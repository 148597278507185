import { Folder } from 'lucide-react';
import styles from './FolderItem.module.css';

const FolderItem = ({ name }) => (
	<div className={styles.folderItem}>
		<Folder className={styles.folderIcon} size={45} />
		<span className={styles.folderName}>{name}</span>
	</div>
);

export default FolderItem;
