import React from 'react';
import { useNavigate } from 'react-router-dom';
import FolderItem from './FolderItem/FolderItem';
import WhiteMainBackgroundWithBackButton from '../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton';

const KnowledgeCenter = () => {
	const navigate = useNavigate();

	const handleBackButton = () => {
		navigate(-1);
	};

	return (
		<WhiteMainBackgroundWithBackButton
			heading='Knowledge Center'
			backButtonAction={handleBackButton}>
			<div className='p-4'>
				<div className='space-y-2'>
					<FolderItem name='Best Practices' />
					<FolderItem name='Content Team Guides and Resources' />
				</div>
			</div>
		</WhiteMainBackgroundWithBackButton>
	);
};

export default KnowledgeCenter;
